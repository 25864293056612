const defaultToggles = {
  checkout_isBopusEnabled: false,
  sitewide_isContentfulEnabled: true,
  showFreeShippingLabel: false,
  facebookConversionApiEnabled: true,
  isSkipCartEnabled: false,
  isCrawlerEnabled: false,
  isLoggingEnabled: true,
  isExtendWarrantyEnabled: true,
  isLoyaltyProgramEnabled: true,
  displayLoyaltyMaintenance: false,
  showSpiffyPaymentMethods: true,
  applePayEnabled: true,
  miniCartApplePayCtaEnabled: true,
  pdpApplePayCtaEnabled: true,
  cartApplePayCtaEnabled: false,
  checkoutApplePayCtaEnabled: true,
  payPalEnabled: true,
  pdpPayPalCtaEnabled: true,
  pdpPayCollectionVariantCtaEnabled: false,
  miniCartPayPalEnabled: true,
  isShippedShieldEnabled: true,
  userCSCPaymentsEnabled: true,
  braintreeAmexBlocked: true,
  isAccountPaymentShowing: false,
  stripeUsersToggledOn: true,
  guestCSCPaymentsEnabled: true,
  guestZgoldEnrollmentEnabled: true,
  enrollToShoptokenViaSFAPI: false,
  isStripeApplePayEnabled: true,
  isStripePDPApplePayEnabled: true,
  isStripeCartApplePayEnabled: true,
  isStripeCheckoutApplePayEnabled: true,
  isStripeMiniCartApplePayEnabled: true,
}

const manualEnvironmentOverrides = {
  local: {
    forceShippedShieldOn: true,
    enrollToShoptokenViaSFAPI: true,
  },
  qa: {},
  staging: {
    forceShippedShieldOn: true,
    enrollToShoptokenViaSFAPI: true,
  },
  test: {
    isLoggingEnabled: false,
  },
  production: {
    isLoggingEnabled: false,
    isShippedShieldEnabled: false,
    isCrawlerEnabled: true,
  },
}

const setFeatureToggles = (environmentName) => ({
  ...defaultToggles,
  ...manualEnvironmentOverrides[environmentName]
})

module.exports = setFeatureToggles
