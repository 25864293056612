/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import Axios from 'axios'

import {
  SHOP_ORIGIN,
  SEARCH_API_V2,
  SEARCH_ORIGIN,
  PARKY_API,
} from '@/services/Configuration'

import { ClientSideCache } from '@/services/ClientSideCache'

import { chunkerizeArray } from '@/helpers/chunkerizeArray'
import { getProductInventory } from '@/services/Product/getProductInventory'
import type { Product as ProductType } from '@/types'
import { getRecommendedProducts } from './getRecommendedProducts'

const unique = <T extends string | number>(list: T[]) => (
  Object.keys(
    list.reduce((hash, key) => ({ ...hash, [key]: true }), {}),
  )
)

const CONTENT_TYPE_HEADER = { 'Content-Type': 'application/json' }
const BROWSER_AUTOMATED_HEADERS = {
  referer: `${SHOP_ORIGIN}/`,
  origin: SHOP_ORIGIN,
}

// Add in headers that the browser is not going to be able to provide, if needed.
const headers = (
  process.browser ? CONTENT_TYPE_HEADER : { ...CONTENT_TYPE_HEADER, ...BROWSER_AUTOMATED_HEADERS }
)

const CacheBySkus = ClientSideCache((skus: string[]) => skus.join(','))

export const Product = {
  bySkus: CacheBySkus(async (skus: string[]) => (await Axios.get<Array<ProductType>>(`${SEARCH_API_V2}/product`, { params: { skus: skus.toString() } })).data),

  inventory: getProductInventory,
  async quicklook(productId) {
    return (await Axios.get('/products.php', {
      params: { productId },
      headers: {
        'stencil-config': '{}',
        'stencil-options': '{"render_with":"products/quick-view"}',
      },
    })).data
  },
  async quicklookJson(productId) {
    return (await Axios.get('/products.php', {
      params: { productId },
      headers: {
        'stencil-config': '{}',
        'stencil-options': '{"render_with":"products/quicklook-json"}',
      },
    })).data
  },
  async getProductById({ ids, _source }: {
    ids: string[] | number[],
    _source: string[],
  }) {
    const validIds = [...ids].reduce((acc, id) => (
      (typeof id === 'string' || typeof id === 'number') && !!id
        ? [...acc, String(id)]
        : acc
    ), [])
    const productIdsPerRequest = chunkerizeArray(validIds, 25)
    const requests = await Promise.all(
      productIdsPerRequest.map((chunkIds) => (
        Axios.get(`${SEARCH_API_V2}/product`, { params: { ids: unique(chunkIds).join(','), _source } })
      )),
    )
    const itemMetadata = {}
    requests.forEach(({ data }) => {
      Object.assign(itemMetadata, data)
    }, {})
    return itemMetadata
  },
  getRecommendedProducts,
  async getReviews({ product_id }) {
    return (await Axios.get(`${SEARCH_ORIGIN}/sf/review/${product_id}/`)).data.data
  },
  async getProductDataFromSku(sku) {
    const url = `${PARKY_API}/sf/reservation/products/${sku}`
    return (await Axios.get(url)).data.data
  },
  async writeReview({ payload }) {
    return (await Axios.post(`${SEARCH_ORIGIN}/sf/review/write/`, payload, { headers })).data
  },
  headersFor: () => (process.browser ? {
    'Content-Type': 'application/json',
  } : {
    referer: Product.urlFor('/'),
    origin: SHOP_ORIGIN,
    'Content-Type': 'application/json',
  }),
  urlFor: (path) => `${SHOP_ORIGIN}${path}`,
}

export default Product
