import { getCategoryName } from '@/helpers/getCategoryName'
import { error } from '@/services/Log'

const HeapAnalytics = {
  trackPurchase({ order, lineItems, customerId }) {
    const events = lineItems.map((lineItem) => ({
      email: order.billingAddress.email,
      order_id: order.orderId,
      product_id: lineItem.productId,
      name: lineItem.name,
      category: `${getCategoryName(lineItem.category_info)}, ` || '-',
      type: lineItem.type,
      variant: lineItem.variantId,
      sku: lineItem.sku,
      price: lineItem.listPrice,
      quantity: lineItem.quantity,
      discount: lineItem.discountAmount,
      discount_code: lineItem.discounts.join(', ') || '-',
      gross_price: lineItem.listPrice * lineItem.quantity,
      net_price: lineItem.listPrice * lineItem.quantity - lineItem.discountAmount,
      currency: 'USD',
    }))

    try {
      if (customerId) {
        window.heap?.identify?.(customerId)
      }
      events.forEach((event) => window.heap.track('Purchase', event))

      const categoriesFromAllLineItems = []

      lineItems.forEach((lineItem) => {
        categoriesFromAllLineItems.push(`${getCategoryName(lineItem.category_info)},`)
        window.heap.addUserProperties({ [`primaryCategory ${lineItem.sku}`]: `${getCategoryName(lineItem.category_info)}` })
        window.heap.addUserProperties({ [`product ${lineItem.sku}`]: lineItem.name })
      })

      const categoryResult = categoriesFromAllLineItems.join(' ')
      window.heap.addUserProperties({ category: categoryResult })
    } catch (e) {
      error('Heap analytics failed to trackPurchase', e)
    }
  },
  trackOrder({ order }) {
    const event = {
      email: order.billingAddress.email,
      order_id: order.orderId,
      subtotal: order.subtotal,
      total: order.grandTotal,
      shipping: order.shippingCostTotal,
      discount: order.totalDiscount,
      discount_code: order.coupons.join(', ') || '-',
    }

    try {
      window.heap.track('Order', event)
    } catch (e) {
      error('HeapAnalytics trackOrder Failed', e)
    }
  },

  identify({
    customerId,
    customerEmail,
    visitorType,
    customerOrderCount,
    pageType,
  }) {
    try {
      window.heap?.identify?.(customerId)
      window.heap?.addUserProperties({
        customerEmail,
        visitorType,
        customerOrderCount,
        pageType,
      })
    } catch (e) {
      error('Heap Identify error', e)
    }
  },
}

export default HeapAnalytics
