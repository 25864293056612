export const checkAddedExtendWarrantyItemsGA = <T, K>(
  newExtendWarrantyItems: Array<T>,
  extendWarrantyItems: Array<K>,
) => {
  if (newExtendWarrantyItems.length > extendWarrantyItems.length) {
    try {
      window.gtag('event', 'addExtendWarrantyToProduct', { event: 'addExtendWarrantyToProduct', data: {} })
      window.heap?.track('addExtendWarrantyToProduct', {})
    } catch {
      // Nothing to do
    }
  }
}

export default checkAddedExtendWarrantyItemsGA
