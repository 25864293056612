import { error } from '@/services/Log'
import { setContext, captureException } from '@sentry/react'

const promisesRetryCookieName = 'forceSingleImportAttempt'
const sleepMiliSeconds = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms))
const allPromisesWithRetries = async <T extends readonly unknown[] | []>(
  promisesGenerator: () => T,
  attempts = (
    typeof document !== 'undefined' && String(document?.cookie || '').indexOf(`${promisesRetryCookieName}=true`) >= 0
      ? 1
      : 3
  ),
  currentAttempt = 1,
): Promise<{ -readonly [P in keyof T]: Awaited<T[P]> }> => {
  try {
    const modules = await Promise.all(promisesGenerator())
    return modules
  } catch (promiseAllError) {
    error('Failed to load modules', promiseAllError)
    if (currentAttempt >= attempts) {
      setContext(`Failed to execute promises after ${currentAttempt} attempts`, { promisesGenerator })
      captureException(promiseAllError)
      throw promiseAllError
    }
    await sleepMiliSeconds()
    return await allPromisesWithRetries(promisesGenerator, attempts, currentAttempt + 1)
  }
}

export default allPromisesWithRetries
