/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import Axios from 'axios'
import { StencilApiHeaders } from '@/services/Helpers'
import { XsrfResilient } from '@/services/XsrfResilient'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import type { BCCartType } from '@/types/BigCommerce/BCCart'
import { log } from '@/services/Log'
//---------------------------------------------------------------

type MinLineItemProps = { productId: number, quantity: number, variantId: number }
export const addSingleLineItemToCart = (
  cartId: string,
) => async (lineItem: MinLineItemProps) => {
  try {
    const response = await Axios.post<BCCartType>(`/api/storefront/carts/${cartId}/items?include=lineItems.physicalItems.options`, {
      lineItems: [
        {
          productId: lineItem.productId,
          quantity: lineItem.quantity,
          variantId: lineItem.variantId,
        },
      ],
    })
    log('Added line item to cart', { lineItem, cartId, response })
    return response.data
  } catch (err) {
    return null
  }
}

export const add = XsrfResilient(async ({ product_id, quantity, attribute }: {
  product_id: string | number,
  quantity: number,
  attribute?: Record<string, string | number>,
}) => {
  const data = new FormData()
  const headers = StencilApiHeaders('post')
  data.append('qty[]', String(quantity))
  data.append('product_id', String(product_id))
  data.append('action', 'add')
  if (attribute) {
    Object.keys(attribute).forEach((attr) => {
      data.append(`attribute[${attr}]`, String(attribute[attr]))
    })
  }
  return {
    ...(await Axios.post('/remote/v1/cart/add', data, { headers }))?.data?.data,
    quantity,
  }
})

export const addBySku = async (sku:string, quantity: number) => {
  const [{ bySku }] = await allPromisesWithRetries(() => [
    import('@/services/Product/bySku'),
  ])
  const [product] = await bySku({ sku })
  if (!product) {
    throw new Error('Product not found')
  }
  const result = await add({
    product_id: product.id,
    quantity,
  })
  return result
}

export default add
