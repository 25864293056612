import Axios from 'axios'
import { CONTENT_API, CONTENT_PREVIEW } from '@/services/Configuration'

type GetContentWithSlugInput = {
  type: string,
  slug: string
}

export const getContentWithSlug = async <T>({ type, slug }: GetContentWithSlugInput) => (
  (await Axios.get<T>(`${CONTENT_API}/content/${type}/${slug}`,
    { params: { preview: CONTENT_PREVIEW } })).data
)

export default getContentWithSlug
