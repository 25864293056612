const MAX_FIFO_QUEUE_SIZE = 50

type CacheSpaceType = {
  [key: string | number]: unknown
}
type CacheType = {
  [key: number]: CacheSpaceType
}
const ClientSideGlobalFifoCacheStorage: CacheType = {}
let ClientSideGlobalCacheSpaceIndex = 0
const FifoStorage: Array<{ space: number, key: string | number }> = []

export type Appender = <T>(p: { key: string | number, space: number, value: T }) => void
const AppendGlobalCache: Appender = ({ key, space, value }) => {
  ClientSideGlobalFifoCacheStorage[space] = ClientSideGlobalFifoCacheStorage[space] || {}
  ClientSideGlobalFifoCacheStorage[space][key] = value
  FifoStorage.push({ space, key })
  if (FifoStorage.length > MAX_FIFO_QUEUE_SIZE) {
    const removable = FifoStorage.shift()
    if (removable && !(removable.space === space && removable.key === key)) {
      delete ClientSideGlobalFifoCacheStorage[removable.space][removable.key]
    }
  }
}

const GlobalCacheContains = ({ key, space }: {
  key: string | number,
  space: number
}) => (
  typeof ClientSideGlobalFifoCacheStorage[space] !== 'undefined'
  && typeof ClientSideGlobalFifoCacheStorage[space][key] !== 'undefined'
)

const GlobalCacheAccess = <T>({ key, space }: {
  space: keyof CacheType
  key: keyof CacheSpaceType
}) => ClientSideGlobalFifoCacheStorage[space][key] as T

export const ClientSideCache = <T>(
  computation: (...args: T[]) => string,
) => <K>(functor: (...args: T[]) => Promise<K>) => {
  if (!process.browser) {
    return functor
  }
  const space = ClientSideGlobalCacheSpaceIndex
  ClientSideGlobalCacheSpaceIndex += 1
  return (...args: T[]) => {
    const key = computation(...args)
    const cacheHit = GlobalCacheContains({ space, key })
    const value = (
      cacheHit
        ? GlobalCacheAccess<Promise<K>>({ space, key })
        : functor(...args)
    )
    AppendGlobalCache({ space, key, value })
    return value
  }
}

type Fucntor<T, K> = (...args: T[]) => Promise<K>
type Hasher<T> = (...args: T[]) => number | string

interface GenericClientSideCache {
  <T, K>(computation: Hasher<T>): (functor: Fucntor<T, K>) => Fucntor<T, K>
}

export const ClientSideCacheWithGeneric: GenericClientSideCache = <T>(
  computation: (...args:T[]) => number | string,
) => <K>(functor: (...args:T[]) => Promise<K>) => {
  if (!process.browser) {
    return functor
  }
  const space = ClientSideGlobalCacheSpaceIndex
  ClientSideGlobalCacheSpaceIndex += 1
  return async (...args: T[]) => {
    const key = computation(...args)
    const cacheHit = GlobalCacheContains({ space, key })
    const value = await (
      cacheHit
        ? Promise.resolve(GlobalCacheAccess<K>({ space, key }))
        : functor(...args)
    )
    AppendGlobalCache({ space, key, value })
    return value
  }
}
