import Axios from 'axios'
import { SEARCH_ORIGIN } from '@/services/Configuration'
import { Product } from '@/types'
import { ClientSideCache } from '@/services/ClientSideCache'

type RecommendedProductInput = {
  product_id: string | number
}

const CacheByProductId = ClientSideCache((input: RecommendedProductInput) => (
  String(input.product_id)
))

type ProductRecomendation = {
  related_products: Array<Product>
}

type ProductRecomendationResponse = {
  success: boolean
  statusCode: number
  response: ProductRecomendation
}

export const getRecommendedProducts = CacheByProductId(async ({ product_id: productId }) => (
  (await Axios.get<ProductRecomendationResponse>(`${SEARCH_ORIGIN}/sf/recommendation/${productId}/`)).data.response
))

export default getRecommendedProducts
