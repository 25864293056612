import {
  createContext, Dispatch, SetStateAction, useCallback, useContext, useState,
} from 'react'
import { getCookieByName } from '@/helpers/common'
import { getCustomer } from '@/helpers/graphql'
import { FEATURE_TOGGLES } from '@/services/Configuration'

const isLoyaltyProgramEnabled = !!FEATURE_TOGGLES?.isLoyaltyProgramEnabled

type ProductPrice = {
  calculated_price: number
  id: number
  price: number
  sale_price: number
  sku: string
}

type OrdersContextType = {
  isLoyaltyEnabled?: boolean;
  isUserSignedIn?: boolean;
  productPrices?: ProductPrice[];
  loading: boolean;
  error: string | null;
  setIsLoyaltyEnabled: Dispatch<SetStateAction<boolean>>;
  setIsUserSignedIn: Dispatch<SetStateAction<boolean>>;
  setProductPrices: Dispatch<SetStateAction<ProductPrice[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
}

const NavigationContext = createContext<OrdersContextType>({
  loading: true,
  error: null,
  setIsLoyaltyEnabled: () => {},
  setIsUserSignedIn: () => {},
  setProductPrices: () => {},
  setLoading: () => {},
  setError: () => {},
})

interface OrdersProviderProps {
  children,
}

const NavigationProvider = ({
  children,
}: OrdersProviderProps) => {
  const [isLoyaltyEnabled, setIsLoyaltyEnabled] = useState<boolean>()
  const [isUserSignedIn, setIsUserSignedIn] = useState<boolean>()
  const [productPrices, setProductPrices] = useState<ProductPrice[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const value = {
    isLoyaltyEnabled,
    isUserSignedIn,
    productPrices,
    loading,
    error,

    setIsLoyaltyEnabled,
    setIsUserSignedIn,
    setLoading,
    setProductPrices,
    setError,
  }

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

const useProductPrices = () => {
  const { productPrices } = useContext(NavigationContext)
  return { productPrices }
}

const useSetProductPrices = () => {
  const { setProductPrices } = useContext(NavigationContext)
  return (lineItems: ProductPrice[]) => setProductPrices(lineItems)
}

const fetchIsUserSignedIn = async () => {
  const customer = await getCustomer().catch((error) => console.error(error))
  if (customer?.success) {
    return !!customer.customer.email
  }
  return false
}

const useIsUserSignedIn = () => {
  const { isUserSignedIn, loading, error } = useContext(NavigationContext)
  return { isUserSignedIn, loading, error }
}

const useFetchIsUserSignedIn = () => {
  const { setIsUserSignedIn, setLoading } = useContext(NavigationContext)

  return useCallback(async () => {
    setLoading(true)
    setIsUserSignedIn(await fetchIsUserSignedIn())
    setLoading(false)
  }, [setIsUserSignedIn, setLoading])
}

const useLoyalty = () => {
  const { isLoyaltyEnabled, loading, error } = useContext(NavigationContext)
  return { isLoyaltyEnabled, loading, error }
}

const useFetchIsLoyaltyEnabled = () => {
  const { setIsLoyaltyEnabled, setLoading } = useContext(NavigationContext)

  return useCallback(async () => {
    setLoading(true)
    const isSignedIn = await fetchIsUserSignedIn()
    const loyaltyCookieEnabled = getCookieByName('enableRewards') === 'true'

    setLoading(false)
    setIsLoyaltyEnabled(isSignedIn && (loyaltyCookieEnabled || isLoyaltyProgramEnabled))
  }, [setIsLoyaltyEnabled, setLoading])
}

export {
  NavigationProvider,
  useLoyalty,
  useProductPrices,
  useSetProductPrices,
  useFetchIsLoyaltyEnabled,
  useIsUserSignedIn,
  useFetchIsUserSignedIn,
}
