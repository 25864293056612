import Axios from 'axios'

import { ClientSideCache } from '@/services/ClientSideCache'

import { SEARCH_ORIGIN } from '@/services/Configuration'

const ONLINE_STORE_CD = '01'
type SkuAndStoreRequestParams = {
  sku: string,
  store_cd?: string,
}
const CacheBySkuAndStore = ClientSideCache(({ sku, store_cd = ONLINE_STORE_CD }: SkuAndStoreRequestParams) => `${sku}-${store_cd || ''}`)

type ProductInventoryResponse200 = {
  statusCode: 200,
  success: true,
  data: {
    in_stock: string,
    inventory: number
    buffer: number,
    po_eta: null | unknown,
    lead_time: number
    lead_time_max: number
    override_message: string
    override: number
    actual_override_message: string
  }
  error: null
}
type ProductInventoryResponseError = {
  statusCode: number,
  success: false,
  data: {
    actual_override_message: string
    inventory: undefined
    buffer: undefined
    in_stock: undefined
    lead_time: undefined
    lead_time_max: undefined
    override: undefined
    override_message: undefined
    po_eta: undefined
  }
  error: string
}

type ProductInventoryResponse = ProductInventoryResponse200 | ProductInventoryResponseError

export const getProductInventory = CacheBySkuAndStore(async ({
  sku, store_cd = ONLINE_STORE_CD,
}) => {
  const response = await Axios.get<ProductInventoryResponse>(`${SEARCH_ORIGIN}/sf/inventory/store/`, { params: { sku, store_cd } })
  const { data } = response.data
  if (!data) {
    throw new Error(String(response.data?.error || 'Product inventory API error'))
  }
  return response.data.data
})

export default getProductInventory
