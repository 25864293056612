import { GA_TRACKING_ID } from '@/services/Configuration'
import type { ZGallerieGoogleTag } from '@/types/ThirdPartyIntegrations/Gtag'
import { FailedStatus, SuccessfulStatus } from '@/services/Tracking/GTAG/TrackStatuses'
import { hashUserData } from '@/services/Tracking/helpers'

type User = {
  email: string,
  phone?: string,
  zip?: string,
  id?: string,
}
export const trackEvent = <T extends ZGallerieGoogleTag.EventParams>(
  eventName: ZGallerieGoogleTag.EventNames | string,
  eventParams: T,
  eventGroup?:string,
): boolean => {
  const getHashedUserInformationProperty = (user: User | undefined) => (
    !!user && { user: hashUserData(user) })
  const getGroupToSendProperty = (group: string | undefined) => (!!group && { send_to: group })

  if (window.gtag && GA_TRACKING_ID) {
    const gaTransformedEventData = {
      ...eventParams,
      ...getHashedUserInformationProperty(eventParams.user),
      ...getGroupToSendProperty(eventGroup),
    }

    window.gtag(
      'event',
      eventName,
      gaTransformedEventData,
    )
    return SuccessfulStatus
  }
  return FailedStatus
}

export default trackEvent
