export const STORE_PHONE = "424.999.4626";

export const GIFT_BOX_SKU = "990000007";

export const FREE_SHIPPING_AMOUNT = 49.00;

export const EXTEND_SKU = ';xtd;';
export const SHIPPED_SHIELD_SKU = 'SHIPPED_ASSURANCE_'

export const BOPUS_SHIPPING_DESCRIPTION = 'Zgallerie (BOPS)'
export const FREE_SHIPPING_DESCRIPTION = "Free Shipping"
export const STANDARD_SHIPPING_DESCRIPTION = "Zgallerie (Standard)"
export const STANDARD_PLUS_SHIPPING_DESCRIPTION = "Zgallerie (Standard Plus)"

export const PARKY_CREDIT_CARD_TRANSACTION_METHOD = 'Credit Card'
export const PARKY_PAYPAL_TRANSACTION_METHOD = 'PayPal'

export const EXTEND_LOGO = 'https://cdn11.bigcommerce.com/s-whvhnri10h/product_images/uploaded_images/extend-logo.png'
export const SHIPPED_SHIELD_LOGO = 'https://cdn11.bigcommerce.com/s-ccb3mj27tc/products/1821415/images/817186/shield-be9ba978feb179704666141db2d825d1f9d9cc55787fdcbf9885dff04b0dd644__12393.1680276093.220.290.png'

export const LOGIN_PAGE_MESSAGES = {
  forgotPassword: '0',
  changePassword: '1'
}
export const FORGOT_PASSWORD_LOGIN_REDIRECT_MESSAGE: string = "If the entered email address is associated with this store, you will receive a password reset email. If you don't receive this e-mail, please check your junk mail folder or contact us for further assistance."
export const CHANGE_PASSWORD_LOGIN_REDIRECT_MESSAGE: string = "Your password has been successfully updated."

export const FULL_FOLD_LENGTH: number = 60

export const BRAND:string = 'Z Gallerie'
export const SCROLL_PRODUCT_POSITION_SESSION_KEY = "scroll-product-position"


export const TABLET_BREAKPOINT = 768

export const PLP_ITEMS_PER_PAGE = 60
export const GA4_GROUP: string = 'GA4'
