type CustomerData = {
  customerId: string,
  customerEmail: string
}
export const getCustomerDetails = (): CustomerData | Record<string, never> => {
  const customerDetails = localStorage.getItem('customer')
  const customer: CustomerData | Record<string, never> = (
    customerDetails
      ? JSON.parse(customerDetails)
      : {}
  )
  return customer
}

export default getCustomerDetails
