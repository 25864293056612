/* eslint-disable no-console */
import { FEATURE_TOGGLES } from '@/services/Configuration'
import Cookies from 'js-cookie'

const isLoggingEnabledFromFeatureToggle = !!FEATURE_TOGGLES?.isLoggingEnabled
const isTracingEnabled = !!FEATURE_TOGGLES?.enableLogsTracing

const checkIfLoggingIsEnabled = () => {
  if (isLoggingEnabledFromFeatureToggle) {
    return true
  }
  try {
    const cookieToggledLogs = Cookies.get('_toggleLogsOn') === 'true'
    return cookieToggledLogs
  } catch (err) {
    // Fail silently
  }
  return false
}
export type LogOperation = 'debug' | 'log' | 'error' | 'warn' // keyof (typeof console)
const baseLog = (level: LogOperation, ...args: unknown[]) => {
  const isLoggingEnabled = checkIfLoggingIsEnabled()
  if (isLoggingEnabled && typeof console[level] === 'function') {
    if (isTracingEnabled) { console.trace() }
    console[level]?.(Date.now(), new Date(), ...args)
  }
}

export const debug = (...args: unknown[]) => baseLog('debug', ...args)

export const log = (...args: unknown[]) => baseLog('log', ...args)

export const error = (...args: unknown[]) => baseLog('error', ...args)

export const warn = (...args: unknown[]) => baseLog('warn', ...args)
