import Axios from 'axios'
import min from 'lodash/min'
import max from 'lodash/max'
import HeapAnalytics from '@/services/Tracking/Heap/HeapAnalytics'
import { INVENTORY_API, SHOP_ORIGIN } from '@/services/Configuration'
import { FREE_SHIPPING_AMOUNT } from '@/data/constants'
import Product from '../services/Product'
import { error } from '@/services/Log'
export { getCookieByName } from '@/helpers/getCookieByName'
import { getCustomerDetails } from '@/helpers/getCustomerDetails'

const CONTENT_TYPE_HEADER = { 'Content-Type': 'application/json' }
const BROWSER_AUTOMATED_HEADERS = {
  referer: `${SHOP_ORIGIN}/`,
  origin: SHOP_ORIGIN,
}


//TODO: move to GTAG tools
export const loginPromptClosedGA = async () => {
  try {
    //!* eslint-disable-next-line @typescript-eslint/no-unsafe-call *!//
    gtag('event', 'checkoutLoginPromptClosed', { event: 'checkoutLoginPromptClosed', data: {} })
  } catch (e) {

  }
}

export const customerGA = async (cartAmount, pageType) => {
  try {
    const customer = getCustomerDetails()
    const customerId = customer?.customerId
    const customerEmail = customer?.customerEmail
    const orderCount = await getCustomerOrderCount(customerId, customerEmail)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'customer',
      visitorType: customerId ? 'Logged In' : 'Guest', // Guest OR Logged In
      customerId, // customer ID
      customerEmail, // Hashed customer email
      customerOrdersCount: orderCount, // Lifetime orders for customer
      customerTotalSpent: '', // Lifetime customer value for customer
      cartTotal: cartAmount, // should be the subtotal of the shopping cart
      pageType, // should be one of the following: home|category|product|cart|searchresults|checkout|purchase|other|account
    })
  } catch (e) {
    error('customerGA event failed: ', e)
  }
}


export const checkoutWithCitizensPayHeap = async () => {
  try {
    // eslint-disable-next-line no-undef
    heap.track('checkoutWithCitizensPay', {})
  } catch (e) {

  }
}


export const customerHeap = async (cartAmount, pageType) => {
  const customer = getCustomerDetails()
  const customerId = customer?.customerId
  const customerEmail = customer?.customerEmail

  const orderCount = await getCustomerOrderCount(customerId, customerEmail)
  HeapAnalytics.identify({
    visitorType: customerId ? 'Logged In' : 'Guest',
    customerId,
    customerEmail,
    customerOrdersCount: orderCount,
    pageType,
  })
}

export const getInventoryStock = async (sku, store_cd) => {
  const response = await Product.inventory({sku, store_cd})
  return response.inventory
}

export const formatPrice = (price, digit = 2) => {
  // using parseFloat to make sure this function works for both number and string
  const floatPrice = parseFloat(price)
  if (!isFinite(floatPrice)) {
    return price
  }
  return `$${floatPrice.toFixed(digit)}`
}

export const handleQTYInput = (e) => {
  if(/^(\s*|\d{1,4})$/.test(e.target.value)) {
    return e.target.value.trim()
  }
  return null
}

const formatPriceRange = (minPrice, maxPrice, digit = 2) =>
// using parseFloat to make sure this function works for both number and string
  `${formatPrice(minPrice, digit)} - ${formatPrice(maxPrice, digit)}`

export const parsePrice = (price) => {
  if (typeof price === 'number') {
    return price
  }
  return Number.parseFloat(price.startsWith('$') ? price.substring(1) : price)
}

export const getPriceOrPriceRange = (product, priceType) => {
  const { variants } = product

  const minPrice = variants ? min(variants.map((variant) => parseFloat(variant[priceType]))) : product[priceType]
  const maxPrice = variants ? max(variants.map((variant) => parseFloat(variant[priceType]))) : product[priceType]

  return minPrice === maxPrice
    ? formatPrice(minPrice)
    : formatPriceRange(minPrice, maxPrice)
}

export const checkIfInStock = (availablity) => !!~availablity.indexOf('In stock')

export const validZip = (value) => value && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)

export const isFurniture = (additional_info) => additional_info && additional_info.is_furniture && parseInt(additional_info.is_furniture[0], 10)

export const priceValue = (price) => price.split('$')[1]
export const isProductFreeShipping = (price) => {
  let freeShipping
  price = `${price}`
  if (price.includes('-')) {
    freeShipping = price.split('-').some((value) => priceValue(value) >= FREE_SHIPPING_AMOUNT)
  } else {
    freeShipping = priceValue(price) >= FREE_SHIPPING_AMOUNT
  }
  return freeShipping
}

export const getStoreAddress = async (store_cd) => {
  const headers = process.browser ? CONTENT_TYPE_HEADER : { ...CONTENT_TYPE_HEADER, ...BROWSER_AUTOMATED_HEADERS }
  return (await Axios.post(`${INVENTORY_API}/api/get_store_addr_by_cd`, { store_cd }, { headers })).data.result
}

export const removeSpecialChars = (string, regex = /[^a-zA-Z \s0-9,'\-#.*&]/g) => `${string}`.replace(regex, '')
