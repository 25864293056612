// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { ExtraErrorData } from '@sentry/integrations'
import { ENVIRONMENT_NAME } from '@/services/Configuration.js'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = ENVIRONMENT_NAME || process.env.ENVIRONMENT_NAME || 'development'

Sentry.init({
  dsn: SENTRY_DSN || 'https://e17ae7752d684301a9f05c3640902566@o32694.ingest.sentry.io/4505110708420608',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  environment: SENTRY_ENVIRONMENT,

  // Setting this option to true will print useful information to
  // the console while you're setting up Sentry.
  debug: false,
  normalizeDepth: 11,
  integrations: [new ExtraErrorData({ depth: 10 })],
  ignoreErrors: [/^Product not found for sku: ([\w]*)$/],
})
