const getNewChunk = <T>(): T[] => []
export function chunkerizeArray <T>(array: T[] = [], chunkSize = 25) {
  const finalArray:T[][] = []
  if (Array.isArray(array) && typeof chunkSize === 'number') {
    for (let i = 0; i < array.length; i += 1) {
      if (i % chunkSize === 0) {
        finalArray.push(getNewChunk<T>())
      }
      const chunkIndex = parseInt(String(i / chunkSize), 10)
      finalArray[chunkIndex].push(array[i])
    }
  }
  return finalArray
}

export default chunkerizeArray
