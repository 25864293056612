import { mapStateToAcronym, mapStateToFullName } from '@/helpers/stateNameAcronymMapping'
import type { StandardAddress } from '@/types/ShopFront/CheckoutStandards'

export const standardizeAddress = ({
  address_type: addressType,
  country_iso2: countryCode,
  customer_id: customerId,
  first_name: firstName,
  last_name: lastName,
  state: stateName,
  state_or_province,
  street_1: street1,
  street_2: street2,
  address1,
  address2,
  zip,
  postal_code,
  shouldSaveAddress = false,
  ...rest
}: {
  address_type: string
  country_iso2: string
  customer_id: number
  first_name: string
  last_name: string
  state: string
  state_or_province: string
  zip: string
  postal_code: string
  city: string
  company: string
  country: string
  email: string
  phone: string
  customFields: Array<{ fieldId: string, fieldValue: string }>
  shouldSaveAddress?: boolean
} & ({
  street_1: string
  street_2: string
  address1?: string
  address2?: string
} & {
  street_1?: string
  street_2?: string
  address1: string
  address2: string
})): StandardAddress => ({
  addressType,
  countryCode,
  customerId,
  firstName,
  lastName,
  stateName: mapStateToFullName(String(stateName)) || mapStateToFullName(String(state_or_province)),
  stateAcronym: (
    mapStateToAcronym(String(stateName)) || mapStateToAcronym(String(state_or_province))
  ),
  street1: (street1 || address1 || ''),
  street2: (street2 || address2 || ''),
  zip: (zip || postal_code || ''),
  shouldSaveAddress,
  ...rest,
})

export default standardizeAddress
