/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
const AUTHENTICITY_TOKEN_ERROR_MESSAGE = 'Sorry, your request could not be processed as it contained an invalid security token. Please refresh and try again.'

export const XsrfResilient = <T, K>(asyncFunctor: (...args: K[]) => Promise<T>) => (
  async (...args: K[]) => {
    try {
      return await asyncFunctor(...args)
    } catch (possibleXsrfResponse) {
      const error = String(possibleXsrfResponse?.response?.data?.error)
      if (error === AUTHENTICITY_TOKEN_ERROR_MESSAGE) {
        return await asyncFunctor(...args)
      }
      throw possibleXsrfResponse
    }
  }
)

export default XsrfResilient
