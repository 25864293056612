import Cookies from 'js-cookie'
import { FEATURE_TOGGLES, type FeatureToggle } from '@/services/Configuration'
import { assertion, mixed, object } from '@recoiljs/refine'
import { AppContext } from 'next/app'

type Query = Record<string, string | string[]>
let querySpy: Query = {}
let cookieSpy: Record<string, string | undefined> = {}

export const appSSRFeatureTogglesSpy = (appContext: AppContext) => {
  if (typeof window === 'undefined') {
    // The next line enable us to use queryParams as feature flags Server Side
    querySpy = Object.entries(appContext.ctx.query).reduce<Query>((query, [key, value]) => {
      if (typeof value === 'undefined') return query
      return {
        ...query,
        [key]: value,
      }
    }, {})
    // global.cookies = appContext.ctx.req?.headers?.cookie
    cookieSpy = (
      (
        appContext.ctx?.req?.headers.cookie
        || ''
      ).split(';').map((cookie) => [
        String(cookie.trim().split('=')[0]).trim(),
        String(cookie.trim().split('=')[1]).trim(),
      ]).reduce((cookies, [key, value]) => ({
        ...cookies,
        [key]: value,
      }), {})
    )
  }
}

type FeatureCheck = (featureFlag: FeatureToggle) => boolean
const checkCookieClientSide: FeatureCheck = (featureFlag) => {
  try {
    return Cookies.get(featureFlag) === 'true'
  } catch (err) {
    return false
  }
}

const checkCookieServerSide: FeatureCheck = (featureFlag) => (
  cookieSpy[featureFlag] === 'true'
)
const setByCookie: FeatureCheck = (featureFlag) => {
  try {
    if (typeof window === 'undefined') {
      return checkCookieServerSide(featureFlag)
    }
    return checkCookieClientSide(featureFlag)
  } catch (err) {
    return false
  }
}

const checkForQueryParamCientSide: FeatureCheck = (featureFlag) => {
  try {
    return (
      // Check for server side before accessing window
      typeof window !== 'undefined'
      && new URLSearchParams(window.location.search).get(featureFlag) === 'true'
    )
  } catch (err) {
    return false
  }
}

const assertQueryHolder = (targetFlag: FeatureToggle) => assertion(
  object({
    [targetFlag]: mixed(),
  }),
)
const checkForQueryParamServerSide: FeatureCheck = (featureFlag) => {
  try {
    return (
      // Check for server side before accessing window
      typeof global !== 'undefined'
      && assertQueryHolder(featureFlag)(querySpy)[featureFlag] === 'true'
    )
  } catch (err) {
    console.error(`Failed to check SSR query: ${String(err)}`)
    return false
  }
}
const setByQueryParam: FeatureCheck = (featureFlag) => {
  try {
    if (typeof window === 'undefined') {
      return checkForQueryParamServerSide(featureFlag)
    }
    return checkForQueryParamCientSide(featureFlag)
  } catch (err) {
    return false
  }
}

const setByFeatureToggle = (featureFlag: FeatureToggle): boolean => !!FEATURE_TOGGLES[featureFlag]

export const isFeatureEnabled = (featureFlag: FeatureToggle): boolean => (
  setByFeatureToggle(featureFlag)
  || setByQueryParam(featureFlag)
  || setByCookie(featureFlag)
)

export default isFeatureEnabled
