import type { StandardCustomer } from '@/types/ShopFront/StandardCustomer'
import { log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { PARKY_API } from '@/services/Configuration'
import { standardizeCustomer } from '@/services/Standardizers/checkout/standardizeCustomer'
import Axios from 'axios'
// This function will return a StandardCustomer which will have all props needed
// This will allow us to make the customer TS consistent across the app

export type ParkyCustomerResponse = {
  data: Array<{
    accepts_product_review_abandoned_cart_emails: boolean
    address_count : number
    addresses: Array<{
      address1: string
      address2: string
      address_type: string
      city: string
      company: string
      country: string
      country_code: string
      first_name: string
      id: number
      last_name: string
      phone: string
      postal_code: string
      state_or_province: string
    }>
    authentication: {
      force_password_reset: boolean
    }
    channel_ids: null | Array<number>
    company: string
    customer_group_id: number
    date_created: string
    date_modified: string
    email: string
    first_name: string
    id: number
    last_name: string
    notes: string
    origin_channel_id: number
    phone: string
    registration_ip_address: string
    store_credit_amounts: Array<{
      amount: number
    }>
    tax_exempt_category: string
  }>
}

const getCustomerFromParky = async ({ customerId }: { customerId: number }) => {
  // TODO: We need documentation regarding this API
  try {
    const customer = ((await Axios.post<ParkyCustomerResponse>(`${PARKY_API}/sf/customer/data/`, { customer_id: customerId })).data?.data || [])[0]
    if (customer) {
      return standardizeCustomer(customer)
    }
  } catch (err) {
    log('Failed to retrieve Customer', err)
  }
  return null
}

export const getCustomer = async (): Promise<null | StandardCustomer> => {
  log('getCustomer Start')
  try {
    const [
      { getCustomer: getCustomerFromGraphQL },
    ] = await allPromisesWithRetries(() => [
      import('@/helpers/graphql'),
    ])
    const graphQLCustomerInfo = await getCustomerFromGraphQL()
    if (!graphQLCustomerInfo?.customer) {
      throw new Error(`Failed to get customer from GraphQL: ${JSON.stringify(graphQLCustomerInfo)}`)
    }
    const customerInfo = await getCustomerFromParky({
      customerId: graphQLCustomerInfo.customer.entityId,
    })
    if (!customerInfo) {
      throw new Error(`Failed to get customer from Parky: ${JSON.stringify(graphQLCustomerInfo)}`)
    }
    return {
      ...graphQLCustomerInfo.customer,
      ...customerInfo,
    }
  } catch (err) {
    log('Unable to retrieve a valid customer', err)
  }
  return null
}

export default getCustomer
