import { sha256 } from 'js-sha256'

type User = {
  email?: string;
  phone?: string;
  zip?: string;
  id?: string;
}

const HASHING_NOT_REQUIRED_KEYS = ['zip']
export const getProductName = (name: string): string => name?.split('~')?.[0]?.trim()

export const hashUserDataValue = (value?: string) => (value ? sha256(String(value).trim().toLowerCase()) : '')

export const hashUserData = (userData: User) => Object.entries(userData)
  .reduce((result, [key, value]) => (
    { ...result, [key]: HASHING_NOT_REQUIRED_KEYS.includes(key) ? `${value}` : hashUserDataValue(value != null ? `${value}` : '') }),
  {} as User)

export default {
  getProductName,
  hashUserData,
  hashUserDataValue,
}
