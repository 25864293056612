import { FEATURE_TOGGLES } from '@/services/Configuration'
import { isLoyaltyCookieEnabled } from '@/services/FramedCheckout/loyaltyServices/isLoyaltyCookieEnabled'
import { isDTPCustomer } from '@/helpers/isDTPCustomer'
import { standardizeAddress } from '@/services/Standardizers/checkout/standardizeAddress'
import type { StandardCustomer } from '@/types/ShopFront/StandardCustomer'

const { isLoyaltyProgramEnabled, displayLoyaltyMaintenance } = FEATURE_TOGGLES ?? {}

/**
 * Standardize the address returned from storefront checkout API
 * which contains a different schema. This works for billingAddress as well
 * as shippingAddress
 * https://developer.bigcommerce.com/api-reference/storefront/checkouts/checkout/checkoutsbycheckoutidget
 * {
 *  billingAddress: {},
 *  consignments: [{
 *    shippingAddress
 *  }]
 * }
 * This will be standardized into the common/address.json schema defined
 * in csc-checkout. Please double check.
 *
 */
export const standardizeCustomer = ({
  addresses,
  company,
  customer_group_id: customerGroupId,
  date_created: createdAt,
  date_modified: updatedAt,
  email,
  // eslint-disable-next-line camelcase
  first_name,
  firstName = first_name,
  id,
  // eslint-disable-next-line camelcase
  last_name,
  lastName = last_name,
  phone,
  // eslint-disable-next-line camelcase
  tax_exempt_category,
  taxExemptCategory = tax_exempt_category,
  store_credit_amounts: storeCreditAmounts,
}: {
  addresses: Array<unknown>,
  company: string,
  customer_group_id: number,
  date_created: string,
  date_modified: string,
  email: string,
  id: number,
  phone: string,
  tax_exempt_category: string,
  taxExemptCategory?: string,
  store_credit_amounts: Array<{
    amount: number,
  }>,
} & ({
  first_name: string,
  last_name: string,
  firstName?: string,
  lastName?: string,
} | {
  first_name?: string,
  last_name?: string,
  firstName: string,
  lastName: string,
})): StandardCustomer => ({
  addresses: addresses.map(standardizeAddress),
  company,
  addressCount: addresses.length,
  attributeCount: 0,
  customerGroupId,
  createdAt,
  updatedAt,
  email,
  firstName: firstName || first_name || '',
  id,
  lastName: lastName || last_name || '',
  phone,
  taxExemptCategory,
  storeCreditAmounts,
  isLoyaltyCustomer: (
    (isLoyaltyCookieEnabled() || !!isLoyaltyProgramEnabled || !!displayLoyaltyMaintenance)
    && !!window?.shoptoken?.isCustomerInLoyaltyProgram?.()
  ),
  isDTPCustomer: isDTPCustomer({ customerGroupId }),
})

export default standardizeCustomer
