/* eslint-disable no-shadow */
import Cart from '@/services/Cart'
import { checkAddedExtendWarrantyItemsGA } from '@/helpers/checkAddedExtendWarrantyItemsGA'

export const ExtendWarranty = {
  initXPDP(productSku) {
    window.ExtendWarrantyService = ExtendWarranty

    // Add timeout to ensure add to cart button has finished rendering
    this.productOptionsElement = document.querySelector('#add-to-cart-form .product-options')
    this.addToCartButton = document.querySelector('#form-action-addToCart')
    window.Extend?.buttons.destroyAll()
    window.Extend?.buttons.render('#extend-offer', { referenceId: productSku })

    const url = new URL(window.location.href)
    if (url.searchParams.get('sku')) {
      this.processVariant(url.searchParams.get('sku'))
    }
  },
  processVariant(sku) {
    window.Extend?.buttons.instance('#extend-offer')?.setActiveProduct(sku)
    this.normalize()
  },
  async openModal({ sku, quantity }) {
    const cart = await this.getCart()
    const extendWarrantyItems = cart.lineItems.customItems.filter(({ sku }) => sku.includes(';xtd;'))
    await new Promise((resolve, reject) => {
      window.Extend?.modal.open({
        referenceId: sku,
        onClose: (plan) => {
          window.ExtendBigCommerce.addPlanToCart({
            sku, plan, quantity, cart,
          }, (error, result) => (error ? reject(error) : resolve(result)))
        },
      })
    })
    const newCart = await ExtendWarranty.getCart()
    const newExtendWarrantyItems = newCart.lineItems.customItems.filter(({ sku }) => sku.includes(';xtd;'))
    checkAddedExtendWarrantyItemsGA(newExtendWarrantyItems, extendWarrantyItems)
  },
  async getCart() {
    if (!window.Extend || !window.ExtendBigCommerce) {
      return null
    }

    return new Promise((resolve, reject) => {
      window.ExtendBigCommerce.getCart((error, cart) => (error ? reject(error) : resolve(cart)))
    })
  },
  async getOffer(sku) {
    return window.Extend?.getOffer(sku)
  },
  async isExtendProduct(sku) {
    const offer = await window.Extend?.getOffer(sku)
    return Boolean(offer.product.title)
  },
  async normalize() {
    const cart = await ExtendWarranty.getCart()
    if (!cart) {
      return
    }

    const extendWarrantyItems = cart.lineItems.customItems.filter(({ sku }) => sku.includes(';xtd;'))

    const removeUnusedWarranties = async () => Promise.all(
      extendWarrantyItems.map(async (extendWarrantyItem) => {
        const physicalItem = cart.lineItems.physicalItems
          .find(({ sku }) => sku === extendWarrantyItem.sku.split(';xtd;')[1])

        if (!physicalItem) {
          await Cart.updateQuantity(({
            id: extendWarrantyItem.id,
            quantity: 0,
          }))
        }
      }),
    )

    const normalizeWarrantyQuantity = async () => Promise.all(
      cart.lineItems.physicalItems.map(async (physicalItem) => {
        const physicalItemWarranties = extendWarrantyItems
          .filter(({ sku }) => sku.split(';xtd;')[1] === physicalItem.sku)

        await Promise.all(
          physicalItemWarranties.map(async (warrantyItem, index) => {
            await Cart.updateQuantity({
              id: warrantyItem.id,
              quantity: physicalItemWarranties.length === index + 1 ? physicalItem.quantity : 0,
            })
          }),
        )
      }),
    )

    await removeUnusedWarranties()
    await normalizeWarrantyQuantity()

    // TODO: remove second normalization call
    await normalizeWarrantyQuantity()
  },
  createCartWithProduct(productId) {
    const data = JSON.stringify({
      action: 'add',
      product_id: productId,
      'qty[]': 1,
    })

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          resolve({ status: xhr.status, data: xhr.response })
        }
      })
      xhr.addEventListener('error', (event) => {
        reject(event)
      })
      xhr.open('POST', 'remote/v1/cart/add')
      xhr.setRequestHeader('content-type', 'application/json')
      xhr.responseType = 'json'
      xhr.send(data)
    })
  },
  initXMiniCart() {
    if (window.Extend && window.ExtendBigCommerce) {
      window.ExtendBigCommerce.getCart((error, cart) => {
        if (cart) {
          window.ExtendBigCommerce.normalizeCart({ cart, balance: true }, () => {})
        }
      })
    }
  },
  extendCatalogOffers(cartUrl) {
    if (window.Extend && window.ExtendBigCommerce) {
      document.querySelector('[data-button-type="add-cart"]').on('click', (e) => {
        e.preventDefault()
        e.stopImmediatePropagation()

        const sku = e.currentTarget.getAttribute('data-product-sku')
        const productId = e.currentTarget.getAttribute('data-product-id')
        const href = e.currentTarget.getAttribute('href')

        window.ExtendBigCommerce.getCart((_, cart) => {
          if (cart) {
            window.Extend?.modal.open({
              referenceId: sku,
              onClose: (plan, product) => {
                if (plan && product) {
                  window.ExtendBigCommerce.addPlanToCart({ sku, plan, cart }, () => {
                    window.location = href
                  })
                } else {
                  window.location = href
                }
              },
            })
          } else {
            ExtendWarranty.createCartWithProduct(productId).then((res) => {
              if (!res.data) {
                window.location = cartUrl
                return
              }
              window.Extend?.modal.open({
                referenceId: sku,
                onClose: (plan, product) => {
                  if (plan && product) {
                    window.ExtendBigCommerce.addPlanToCart({ sku, plan }, () => {
                      window.location = cartUrl
                    })
                  } else {
                    window.location = cartUrl
                  }
                },
              })
            })
          }
        })
      })
    }
  },
}

export default ExtendWarranty
