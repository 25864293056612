export const StencilApiHeaders = (method='get', config={}, options={}, contentType='application/json') => ({
  'stencil-config': JSON.stringify(config),
  'stencil-options': JSON.stringify(options),
  ...(method === 'get'
    ? {}
    : {
      'content-type': contentType,
      'accept': '*/*'
      })
})
