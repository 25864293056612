import { NextPageContext } from 'next'

export const handleRedirect = (res : NextPageContext['res'], location: string, statusCode = 302): void => {
  if (process.browser && typeof window !== 'undefined' && window.location.href) {
    window.location.href = location
  } else if (res && typeof res.writeHead === 'function') {
    res?.setHeader?.('cache-control', 'private')
    res?.writeHead?.(statusCode, { location })
    res.end()
  }
}

export default handleRedirect
