/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import Axios from 'axios'
import { StencilApiHeaders } from '@/services/Helpers'

import { XsrfResilient } from '@/services/XsrfResilient'

import { XsrfResilient as TypedXsrfResilient } from '@/services/TypedHelpers'
import {
  SEARCH_ORIGIN,
  RECREATE_URL,
  BOPUS_FIELD,
} from '@/services/Configuration'
import type { Checkout } from '@/types/Checkout'
import type { BOPUSInfo } from '@/types/Cart'
import { error } from '@/services/Log'
import type { BCCartType } from '@/types/BigCommerce/BCCart'
import { add } from '@/services/Cart/add'

const createACart = XsrfResilient(async ({
  productId,
  quantity,
  variantId,
}: {
  productId: number,
  quantity: number,
  variantId?: number | undefined,
}) => Axios.post<BCCartType>('/api/storefront/carts', {
  lineItems: [{
    productId,
    quantity,
    variantId,
  }],
}, { headers: StencilApiHeaders('post') }))

export const Cart = {
  updateQuantity: XsrfResilient(async ({ id, warrantyId = '', quantity }: {
    id: string,
    warrantyId?: string,
    quantity: number,
  }) => {
    const data = new FormData()
    const headers = StencilApiHeaders('post')
    data.append('items[0][id]', id)
    data.append('items[0][quantity]', String(quantity))
    if (warrantyId) {
      data.append('items[1][id]', warrantyId)
      data.append('items[1][quantity]', String(quantity))
    }
    const { data: { data: { status } } } = (await Axios.post('/remote/v1/cart/update', data, { headers }))
    return status
  }),
  createACart,
  addVariant: XsrfResilient(async ({ product_id, quantity, variantId }: {
    product_id: number,
    quantity: number,
    variantId?: number,
  }) => {
    const { data: carts } = await Axios.get<Array<{ id: string }>>('/api/storefront/carts')
    const cartId = carts?.[0]?.id
    if (!cartId) {
      return (await createACart({ productId: product_id, quantity, variantId })).data
    }
    return (await Axios.post<BCCartType>(`/api/storefront/carts/${cartId}/items`, {
      lineItems: [{
        productId: product_id,
        quantity,
        variantId,
      }],
    }, { headers: StencilApiHeaders('post') })).data
  }),
  addItem: async ({ product_id, quantity, attribute }) => ({
    ...await add({ product_id, quantity, attribute }),
    quantity,
  }),
  cartProductPrices: async (skus: string[]) => {
    const headers = { 'Content-Type': 'application/json' }
    const { data: { data } } = (await Axios.post(`${SEARCH_ORIGIN}/sf/cart/products/`, { product: skus }, { headers }))
    return data
  },
  applyCoupon: TypedXsrfResilient(async (code) => {
    const { data } = (await Axios.post<{ data: { status: string, errors: string[] } }>('/remote/v1/apply-code', { code })).data
    return data
  }),
  getCanonicalCode: XsrfResilient(async (cartId, couponCode) => {
    const { data } = (await Axios.get(`${SEARCH_ORIGIN}/sf/cart/${cartId}/coupon/${couponCode}/canonical/`)).data
    return data
  }),
  removeCoupon: XsrfResilient(async (cartId, couponCode) => {
    await Axios.delete(`/api/storefront/checkouts/${cartId}/coupons/${couponCode}`)
  }),

  recreateCart: XsrfResilient(async (cartId) => {
    const { data } = (await Axios.get(`${RECREATE_URL}/${cartId}/recreate`))
    return data
  }),
  createCart: XsrfResilient(async (lineItems) => {
    let data = {}
    try {
      data = (await Axios.post('/api/storefront/carts', lineItems)).data
    } catch (err) {
      error('Error creating cart', err)
    }
    return data
  }),
  storefrontAddItems: XsrfResilient(async (cartId: string, lineItems) => {
    const { data } = (await Axios.post(`/api/storefront/carts/${cartId}/items`, { lineItems }))
    return data
  }),
  deleteCart: XsrfResilient(async (cartId) => {
    const { data } = (await Axios.delete(`/api/storefront/carts/${cartId}`))
    return data
  }),
  addGiftWrapping: XsrfResilient(async (itemId: string) => {
    const formData = new FormData()
    formData.append('action', 'save_giftwrapping')
    formData.append('item_id', itemId)
    formData.append('giftwraptype', 'same')
    formData.append('giftwrapping[all]', '1')
    formData.append('giftmessage[all]', '')
    await Axios.post('/cart.php', formData)
    await Axios.get('/cart.php?action=add&sku=990000007')
  }),
  removeGiftWrapping: XsrfResilient(async (itemId) => {
    await Axios.get(`/cart.php?action=remove_giftwrapping&item_id=${itemId}`)
  }),
  getBOPUSInfo: TypedXsrfResilient(async (cartId: string) => {
    if (!cartId) {
      throw new Error('Cart ID is not provided')
    }
    const checkIsBOPUSItem = (customFields) => customFields.some((field) => field.fieldId === BOPUS_FIELD && field.fieldValue === '1')
    const { data: { consignments } }: { data: Checkout } = await Axios.get(`/api/storefront/checkouts/${cartId}?include=consignments.availableShippingOptions`)
    return consignments.map((consignment) => {
      const {
        shippingAddress: {
          address1,
          address2,
          city,
          stateOrProvince,
          stateOrProvinceCode,
          phone,
          customFields,
          postalCode,
        }, id, lineItemIds,
      } = consignment
      const isBOPUS = checkIsBOPUSItem(customFields)

      const result: BOPUSInfo = {
        address1,
        address2,
        city,
        stateOrProvince,
        stateOrProvinceCode,
        postalCode,
        phone,
        id,
        isBOPUS,
        lineItemIds,
      }
      return result
    })
  }),
}

export default Cart
