import type { TikTokPixelEvent, TikTokPixelTrackOptions } from '@/types/ThirdPartyIntegrations/TikTokPixel'
import type { User } from '@/services/Tracking/Analytics'
import type { RequiredProductAttributes as Product } from '@/services/Tracking/GTAG/convertProductToGA4Item'
import { sha256 } from 'js-sha256'
import { error } from '@/services/Log'

export function trackViewPageTikTok(tryingCounter = 1) {
  if (tryingCounter === 5) {
    error('Could not load TikTok Pixel')
    return
  }
  if (window.ttq) {
    window.ttq.page()
  } else {
    error('TikTok Pixel is not loaded / track')
    setTimeout(() => {
      trackViewPageTikTok(tryingCounter + 1)
    }, 1000)
  }
}

export const trackTikTokPixel = (
  eventName: TikTokPixelEvent,
  options?: TikTokPixelTrackOptions,
  tryingCounter = 1,
) => {
  if (tryingCounter === 5) {
    error('Could not load TikTok Pixel')
    return
  }
  if (window.ttq) {
    window.ttq.track(eventName, options)
  } else {
    error('TikTok Pixel is not loaded / track')
    setTimeout(() => {
      trackTikTokPixel(eventName, options, tryingCounter + 1)
    }, 1000)
  }
}

export const identifyCustomerTikTokPixel = (email: string, phone: string, tryingCounter = 1) => {
  if (tryingCounter === 5) {
    error('Could not load TikTok Pixel')
    return
  }
  if (window.ttq) {
    window.ttq.identify({
      sha256_email: sha256(email),
      sha256_phone_number: sha256(`+${String(phone)}`),
    })
  } else {
    error('TikTok Pixel is not loaded / identify')
    setTimeout(() => {
      identifyCustomerTikTokPixel(email, phone, tryingCounter + 1)
    }, 1000)
  }
}

export const trackTikTokPixelAndIdentifyUser = (
  event: TikTokPixelEvent,
  product: Product,
  user?: User,
) => {
  if (user) {
    identifyCustomerTikTokPixel(user.email, user.phone)
  }

  const price = Number(product.salePrice || 0)

  trackTikTokPixel(event, {
    content_type: 'product',
    currency: 'USD',
    value: price * (product.quantity || 1),
    contents: [{
      price,
      quantity: product.quantity || 1,
      content_name: product.name,
      content_category: product.category,
      content_id: product.sku,
    }],
  })
}
