type ReservedWordsForLogging = 'beginTime' | 'endTime' | 'duration' | 'isError' | 'message'
type NonFlexibleKeys = { [K in ReservedWordsForLogging]?: never } & { event: string }
type RecordOfUnknownsWithoutReservedWords = NonFlexibleKeys & Record<
Exclude<string, ReservedWordsForLogging | 'event'>, unknown>
type VoidRecordProcessor = (more?: Omit<RecordOfUnknownsWithoutReservedWords, 'event'> & { event?: never }) => void
type Identity = <T>(x: T) => T
const identityWithVoidEffect = (sideEffect: (something: unknown) => void) => <T>(x: T): T => {
  sideEffect(x)
  return x
}
const logWithTiming = (
  { event, ...rest }: RecordOfUnknownsWithoutReservedWords,
): [
    VoidRecordProcessor,
    VoidRecordProcessor,
    {
      thenable: Identity,
      catchable: <I, O>(f: (x: I) => O) => (x: I
      ) => O,
    },
  ] => {
  const begin = new Date()
  const beginMs = begin.getTime()
  const beginTime = begin.toISOString()
  // eslint-disable-next-line no-console
  console.log(JSON.stringify({
    ...rest,
    event,
    message: `BEGIN :: ${event}`,
    beginTime,
  }))
  const endLogger = (isError: boolean) => (more: Omit<RecordOfUnknownsWithoutReservedWords, 'event'> = {}) => {
    const end = new Date()
    const endMs = end.getTime()
    const endTime = end.toISOString()
    const duration = endMs - beginMs
    // eslint-disable-next-line no-console
    const functor = isError ? console.error : console.log
    const reason = isError ? 'ERROR' : 'END'
    functor(JSON.stringify({
      ...rest,
      ...more,
      event,
      message: `${reason} :: ${event}`,
      beginTime,
      endTime,
      duration,
      isError,
    }))
  }
  const end = endLogger(false)
  const endError = endLogger(true)
  return [
    end,
    endError,
    {
      thenable: identityWithVoidEffect(end),
      catchable: <I, O>(f: (x: I) => O) => (err: I): O => {
        endError({ err })
        return f(err)
      },
    },
  ]
}

export const timePromise = (
  input: RecordOfUnknownsWithoutReservedWords,
) => async <T>(
  p: Promise<T>,
): Promise<T> => {
  const [end, endError] = logWithTiming(input)
  try {
    const result = await p
    end()
    return result
  } catch (err: unknown) {
    endError({ err })
    return Promise.reject(err)
  }
}

export default logWithTiming
