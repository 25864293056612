import { getCustomer } from '@/services/Account/getCustomer'
import { useEffect } from 'react'
import { trackEvent } from '@/services/Tracking/GTAG/trackEvent'
import { GA4_GROUP } from '@/data/constants'

const useTrackGTMUserInfo = () => {
  useEffect(() => {
    if (window.location.pathname !== '/') {
      return
    }
    const loader = async () => {
      const customer = await getCustomer()
      if (customer && customer?.email) {
        const user = {
          email: customer.email,
          zip: customer?.addresses?.[0]?.zip,
        }
        trackEvent('user_loaded', { user }, GA4_GROUP)
      }
    }
    loader()
  }, [])
}

export default useTrackGTMUserInfo
