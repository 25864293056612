import { getContentWithSlug } from './getContentWithSlug'

export const getComponent = async <T extends {
  message: string
}>({ type, id = 'default' }: {
  type: string,
  id?: string
}) => (
  getContentWithSlug<T>({ type, slug: id })
    .then((data) => {
      if (!('__type' in data)) {
        throw new Error(String(data?.message ?? 'No content found'))
      }
      return data
    })
)
export default getComponent
