import { FEATURE_TOGGLES } from '@/services/Configuration'
import { Sitewide } from '@/services/Sitewide'
import dynamic from 'next/dynamic'
import type { SitewideBanner } from './types'

const BannerV1 = dynamic(import('@/components/Sitewide/Banner/BannerV1/'))
const BannerV2 = dynamic(import('@/components/Sitewide/Banner/BannerV2/BannerV2'))
const BannerV3 = dynamic(import('@/components/Sitewide/Banner/BannerV3/BannerV3'))

const Banner = ({
  version, messaging, callToActionText, callToActionUrl, tiles, countdownTarget,
}: SitewideBanner) => {
  switch (version) {
    case 2:
      return <BannerV2 tiles={tiles} />
    case 3:
      return (
        <BannerV3
          messaging={messaging}
          callToActionText={callToActionText}
          callToActionUrl={callToActionUrl}
          countdownTarget={countdownTarget}
        />
      )
    default:
      return (
        <BannerV1
          messaging={messaging}
          callToActionText={callToActionText}
          callToActionUrl={callToActionUrl}
          tiles={tiles}
          countdownTarget={countdownTarget}
        />
      )
  }
}

Banner.getInitialProps = async () => {
  if (!FEATURE_TOGGLES?.sitewide_isContentfulEnabled) {
    return { banner: null }
  }

  try {
    const banner = await Sitewide.get<SitewideBanner>({ type: 'enhancedBanner', id: 'sitewideBanner' })
    return { banner }
  } catch (e) {
    return { banner: null }
  }
}

export default Banner
