import { log } from '@/services/Log'
import { getCookieByName } from '@/helpers/getCookieByName'

export const isLoyaltyCookieEnabled = () => {
  try {
    const rewardsEnabled = getCookieByName('enableRewards') === 'true'
    return rewardsEnabled
  } catch (e) {
    log('isLoyaltyCookieEnabled error', e)
  }
  return false
}

export default isLoyaltyCookieEnabled
