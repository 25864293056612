type MinCategoryProps = {
  name: string
  path: string
}

export const getCategoryName = <T extends MinCategoryProps>(categories: T[]) => (
  categories?.sort((a, b) => {
    const pathDiff = b.path.split('/').length - a.path.split('/').length
    return pathDiff || (b.name.length - a.name.length)
  })[0]?.name
)

export const getMainCategory = <T extends MinCategoryProps>(categories: readonly T[]) => (
  [...categories]?.sort((a, b) => {
    const pathDiff = b.path.split('/').length - a.path.split('/').length
    return pathDiff || (b.name.length - a.name.length)
  })[0]
)

export default getCategoryName
