import type { NextPageContext } from 'next'
import { handleRedirect } from '@/helpers/navigationHelpers/handleRedirect'
import Axios, { AxiosResponse } from 'axios'
import { SHOP_HASH } from '@/services/Configuration'
import { assertion, object, string } from '@recoiljs/refine'

const locationHeaderHolder = object({
  location: string(),
})

const assertLocationHeader = assertion(locationHeaderHolder, 'Location header is not a string')

const extractLocation = (headers: unknown): string => {
  try {
    return assertLocationHeader(headers).location
  } catch (e) {
    return ''
  }
}

// Add timeout to avoid waiting for the request to BC to finish
const fetchBCRedirect = async (path: string): Promise<AxiosResponse> => Axios.get<AxiosResponse>(
  `https://store-${SHOP_HASH}.mybigcommerce.com${path}`,
  {
    // Add timeout to avoid waiting for the request to BC to finish
    timeout: 500,
    // Add maxRedirects to avoid following redirects
    maxRedirects: 0,
    // Add validateStatus to avoid throwing an error on 3xx responses
    validateStatus: (status) => status >= 200 && status < 400,
  },
)

const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

const isRedirect = (status: number, location: string) => (
  status === 301 || status === 302
) && isValidUrl(location)

// Make request to BC to check if the path is a redirect
export const checkForBigCommerceRedirect = async (
  ctx: NextPageContext,
) => new Promise<void>((resolve) => {
  if (!ctx.asPath) {
    resolve()
    return
  }

  fetchBCRedirect(ctx.asPath)
    .then(({ status, headers }) => {
      const redirectLocationHeader = extractLocation(headers)
      if (redirectLocationHeader && isRedirect(status, redirectLocationHeader)) {
        // Extract the new URL from the response headers
        const redirectUrl = new URL(redirectLocationHeader)
        handleRedirect(
          ctx.res,
          redirectUrl.pathname + redirectUrl.search,
          status,
        )
      }
    })
    .catch(() => {
      // We want to fail silently here
      // debug('Error while checking for redirect', e)
    })
    .finally(() => {
      resolve()
    })
})

export default checkForBigCommerceRedirect
