import { ExtendWarranty } from '@/services/ExtendWarranty'
import { Sitewide } from '@/services/Sitewide'
import shared from '@/styles/screen.module.scss'
import { useWindowSize } from '@/hooks/useWindow'
import {
  NavigationProvider,
  useFetchIsLoyaltyEnabled,
  useFetchIsUserSignedIn,
  useSetProductPrices,
} from '@/components/Sitewide/Navbar/NavigationContext'
import React, { useEffect } from 'react'
import { error } from '@/services/Log'
import dynamic from 'next/dynamic'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { useCart } from '@/hooks/cart'
import logWithTiming, { timePromise } from '@/helpers/logWithTiming'

const DesktopNavigation = dynamic(import('./Desktop'))
const MobileNavigation = dynamic(import('./Mobile'))

type NavBarComponent = React.FC<{
  pageType: string
  productAdded: boolean
  meganav: any
  bannerVersion: number
}>

const Navbar: NavBarComponent = ({
  pageType,
  productAdded,
  meganav,
  bannerVersion,
}) => {
  const { cart } = useCart()
  const lineItems = cart?.lineItems

  const fetchLoyalty = useFetchIsLoyaltyEnabled()
  const fetchIsUserSignedIn = useFetchIsUserSignedIn()
  const setLineItems = useSetProductPrices()

  useEffect(() => {
    if (!lineItems || lineItems?.length === 0) {
      return
    }
    const [, , { thenable, catchable }] = logWithTiming({
      event: 'cartProductPrices',
    })
    import('@/services/Cart/cartProductPrices')
      .then(thenable)
      .then(({ cartProductPrices }) => cartProductPrices(lineItems.map((item) => item.sku)))
      .then(setLineItems)
      .catch(catchable((err) => error('Failed to attache product prices to cart', err)))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(lineItems)])

  useEffect(() => {
    ExtendWarranty.initXMiniCart()
  }, [])

  useEffect(() => {
    fetchIsUserSignedIn()
  }, [fetchIsUserSignedIn])

  useEffect(() => {
    fetchLoyalty()
  }, [fetchLoyalty])

  const { width } = useWindowSize()
  if (width > Number(shared.small)) {
    return (
      <DesktopNavigation
        pageType={pageType}
        meganav={meganav}
        bannerVersion={bannerVersion}
      />
    )
  }
  return (
    <MobileNavigation
      pageType={pageType}
      productAdded={productAdded}
      meganav={meganav}
      bannerVersion={bannerVersion}
    />
  )
}

const fetchBannerVersion = async () => (
  Sitewide.get<{ version?: string }>({ type: 'enhancedBanner', id: 'sitewideBanner' })
    .then((banner: { version?: string }) => String(banner?.version || ''))
    .catch((e) => {
      error('Failed to fetch banner version', e)
      return ''
    })
)

const fetchNavbarInitialProps = async () => {
  const [,, { thenable }] = logWithTiming({ event: 'getContentfulMeganav' })
  return allPromisesWithRetries(() => [
    import('@/services/Navigation'),
  ])
    .then(thenable)
    .then(async ([{ contentfulMeganav }]) => ({
      meganav: await timePromise({ event: 'runContentfulMeganav' })(contentfulMeganav()),
      bannerVersion: await timePromise({ event: 'fetchBannerVersion' })(fetchBannerVersion()),
    }))
}

type InitialPropsFetcher = {
  getInitialProps?: typeof fetchNavbarInitialProps
}

const NavbarWrapper: NavBarComponent & InitialPropsFetcher = ({
  pageType,
  productAdded,
  meganav,
  bannerVersion,
}) => (
  <NavigationProvider>
    <Navbar
      pageType={pageType}
      productAdded={productAdded}
      meganav={meganav}
      bannerVersion={bannerVersion}
    />
  </NavigationProvider>
)

NavbarWrapper.getInitialProps = fetchNavbarInitialProps

export default NavbarWrapper
