import { useWindowScroll as useStaticWindowScroll, useWindowSize as useStaticWindowSize } from 'react-use'
import { useEffect, useState } from 'react'

const useWindowSize = () => {
  const sizes = useStaticWindowSize()

  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    setWindowSize(sizes)
  }, [sizes])

  return windowSize
}
const useWindowScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 })
  const staticWindowScroll = useStaticWindowScroll()

  useEffect(() => {
    setScrollPosition(staticWindowScroll)
  }, [staticWindowScroll, setScrollPosition])

  return scrollPosition
}
export { useWindowScroll }
export { useWindowSize }
