/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { GIFT_BOX_SKU } from '@/data/constants'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { error as logError } from '@/services/Log'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import getCart from '@/services/FramedCheckout/orderServices/getCart'

type CartContextType = {
  cart: StandardCart | null | undefined,
  isLoading: boolean,
  error: string | null | Error,
  setCart: Dispatch<SetStateAction<StandardCart | null>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<string | null>>
}

const CartContext = createContext<CartContextType>({
  cart: null,
  isLoading: true,
  error: null,
  setCart: () => {},
  setIsLoading: () => {},
  setError: () => {},
})

interface CartProviderProps {
  children,
}

const CartInfoProvider = ({
  children,
}: CartProviderProps) => {
  const [cart, setCart] = useState<StandardCart | null | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    getCart().then(setCart).catch(setError)
  }, [])

  return (
    <CartContext.Provider value={{
      cart,
      isLoading,
      error,
      setCart,
      setIsLoading,
      setError,
    }}
    >
      {children}
    </CartContext.Provider>
  )
}

const useCart = () => useContext(CartContext)

const useFetchCart = () => {
  const { setCart, setIsLoading, setError } = useContext(CartContext)
  return useCallback(async () => {
    setIsLoading(true)
    try {
      const cart = await getCart()
      setCart(cart)
      setIsLoading(false)
      return cart
    } catch (e) {
      setError(String(e))
    }
    setIsLoading(false)
    return null
  }, [setCart, setError, setIsLoading])
}

const useSetQuantity = () => {
  const { setIsLoading, setError } = useContext(CartContext)
  const fetchCart = useFetchCart()

  return useCallback(async ({ id, quantity, warrantyId = '' }) => {
    setIsLoading(true)
    try {
      const [{ Cart }] = await allPromisesWithRetries(() => [
        import('@/services/Cart'),
      ])
      await Cart.updateQuantity({ id, quantity, warrantyId: warrantyId || undefined })
      setIsLoading(false)
      return await fetchCart()
    } catch (e) {
      setError(String(e))
    }
    setIsLoading(false)
    return null
  }, [setIsLoading, fetchCart, setError])
}

const useRemoveCoupon = () => {
  const { setIsLoading, setError } = useContext(CartContext)
  const fetchCart = useFetchCart()

  return useCallback(async ({ id, couponCode }) => {
    setIsLoading(true)
    try {
      const [{ Cart }] = await allPromisesWithRetries(() => [
        import('@/services/Cart'),
      ])
      await Cart.removeCoupon(id, couponCode)
      setIsLoading(false)
      return await fetchCart()
    } catch (e) {
      setError(String(e))
    }
    setIsLoading(false)
    return null
  }, [setIsLoading, fetchCart, setError])
}

const useApplyCoupon = () => {
  const { setCart, setIsLoading, setError } = useContext(CartContext)

  return useCallback(async ({ couponCode, cartId }) => {
    setIsLoading(true)
    try {
      const [
        { Cart },
        { onCouponApply },
      ] = await allPromisesWithRetries(() => [
        import('@/services/Cart'),
        import('@/services/Tracking/Analytics/onCouponApply'),
      ])
      const canonicalCode = await Cart.getCanonicalCode(cartId, couponCode)
      if (!canonicalCode) {
        throw new Error('Code not found')
      }
      const data = await Cart.applyCoupon(canonicalCode.internal_code)
      if (data?.status === 'success') {
        const cart = await getCart()
        // Analytics
        try {
          const mergedItems = [...(cart?.lineItems || [])]
            .map((item) => ({
              ...item,
              originalPrice: item.originalPrice ?? item.subtotalPrice / item.quantity ?? 0,
            }))
          await onCouponApply({ cart: { lineItems: mergedItems }, coupon: couponCode })
        } catch (e) {
          logError('applyCoupon:: failed to send analytics: ', e)
        }
        setCart(cart)
        setIsLoading(false)
        return cart
      } throw new Error(data?.errors?.[0])
    } catch (e) {
      setError(String(e))
    }
    setIsLoading(false)
    return null
  }, [setCart, setIsLoading, setError])
}

const useAddGiftWrapping = () => {
  const {
    setIsLoading, setError,
  } = useContext(CartContext)
  const fetchCart = useFetchCart()

  return useCallback(async (itemId: string) => {
    setIsLoading(true)
    try {
      const [{ Cart }] = await allPromisesWithRetries(() => [
        import('@/services/Cart'),
      ])
      await Cart.addGiftWrapping(itemId)
      setIsLoading(true)
      return await fetchCart()
    } catch (e) {
      setError(String(e))
    }
    setIsLoading(false)
    return null
  }, [fetchCart, setIsLoading, setError])
}

const useRemoveGiftWrapping = () => {
  const {
    setIsLoading, setError, cart,
  } = useContext(CartContext)
  const fetchCart = useFetchCart()

  return useCallback(async (itemId: string): Promise<StandardCart | null> => {
    if (!cart?.lineItems?.length) {
      return null
    }
    setIsLoading(true)
    try {
      const [{ Cart }] = await allPromisesWithRetries(() => [
        import('@/services/Cart'),
      ])
      const giftBoxes = cart?.lineItems
        .find((item) => item.sku === GIFT_BOX_SKU)
      if (!giftBoxes) {
        throw new Error('Gift box not found')
      }
      const { id, quantity } = giftBoxes
      await Cart.removeGiftWrapping(itemId)
      await Cart.updateQuantity({ id, quantity: quantity - 1 })
      setIsLoading(true)
      return await fetchCart()
    } catch (e) {
      setError(String(e))
    }
    setIsLoading(false)
    return null
  }, [cart, setIsLoading, fetchCart, setError])
}

export {
  CartInfoProvider,
  useAddGiftWrapping,
  useRemoveGiftWrapping,
  useFetchCart,
  useApplyCoupon,
  useSetQuantity,
  useRemoveCoupon,
  useCart,
}
