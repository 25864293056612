import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { log } from '@/services/Log'
import type { BCCartType } from '@/types/BigCommerce/BCCart'
import { StandardCart } from '@/types/ShopFront/CheckoutStandards'

export const getCart = async (): Promise<StandardCart | null> => {
  log('getCart START')
  const [
    { default: Axios },
    { standardizeCart },
    { getCustomItems },
    { getDigitalItems },
    { generateLineItemsFromCheckout },
    { assertBCCart },
  ] = await allPromisesWithRetries(() => [
    import('axios'),
    import('@/services/Standardizers/checkout/standardizeCart'),
    import('@/helpers/checkoutHelpers/getCustomItems'),
    import('@/helpers/checkoutHelpers/getDigitalItems'),
    import('@/services/FramedCheckout/orderServices/generateLineItemsFromCheckout'),
    import('@/types/BigCommerce/BCCart'),
  ])
  // Returns a Cart. - https://developer.bigcommerce.com/api-reference/storefront/carts/cart/getacart
  const { data } = await Axios.get<BCCartType>('/api/storefront/cart?include=lineItems.physicalItems.options')
  const rawCart = Array.isArray(data) ? data[0] : data
  if (!rawCart) {
    return null
  }
  assertBCCart(rawCart)

  log('rawCart', { rawCart })
  // Adds isFurniture boolean property and availability message to lineItems
  let lineItems = await generateLineItemsFromCheckout(rawCart)
  log('lineItems', { lineItems })
  const customItems = getCustomItems({ lineItems, customItems: rawCart?.lineItems?.customItems })
  log('customItems', { customItems })
  const digitalItems = getDigitalItems(rawCart?.lineItems?.digitalItems)
  log('digitalItems', { digitalItems })
  lineItems = [...lineItems, ...customItems, ...digitalItems]
  log('lineItems', { lineItems })
  const cart = standardizeCart({ ...rawCart, lineItems })
  log('getCart END', { cart })
  return cart
}

export default getCart
