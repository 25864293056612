import { GraphQLStoreFrontJWT, SHOP_ORIGIN } from '@/services/Configuration'
import { error } from '@/services/Log'
import { ClientSideCache } from '@/services/ClientSideCache'
import allPromisesWithRetries from './allPromisesWithRetries'

type HeadersType = undefined | NodeJS.Dict<string | string[]> | { [key: string]: string | string[] }
const GraphQLHeaders = (headers: HeadersType = {}) => ({
  headers: {
    ...headers,
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${GraphQLStoreFrontJWT}`,
  },
})

type SignInGQResponse = {
  data: {
    login: { result: string };
  }
  errors?: { message: string }[];
}

export const signUserIn = async ({ email, password }: { email: string, password: string }) => {
  let success = false
  let message = ''
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const response = await Axios.post<SignInGQResponse>(
      '/graphql',
      {
        query: `mutation Login($email: String!, $password: String!) {
                        login(email: $email, password: $password) {
                          result
                        }
                      }
                      `,
        variables: { email, password },
      },
      GraphQLHeaders(),
    )

    message = response?.data?.errors?.[0]?.message || ''
    if (message === 'Invalid credentials') {
      const newMessage = 'Your email address or password is incorrect. Please try again. If you\'ve forgotten your sign in details, just click the \'Forgot your password?\' link below.'
      return { success, message: newMessage }
    }
    success = response?.data?.data?.login?.result === 'success'
  } catch (err) {
    error('Failed to login via GRAPHQL', err)
  }
  return { success, message }
}

export const signUserOut = async () => {
  let success = false
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const response = await Axios.post<{ data: { logout: { result: string } } }>(
      '/graphql',
      {
        query: `mutation logout {
          logout {
            result
          }
        }`,
      },
      GraphQLHeaders(),
    )
    success = response?.data?.data?.logout?.result === 'success'
    return { success }
  } catch (err) {
    error('Failed to signUserOut', err)
    return { success: false }
  }
}

const CacheInventory = ClientSideCache(({
  productId,
  variantId = 0,
}: {
  productId: number,
  variantId?: number,
}) => `${productId}-${variantId}`)

export const getProductInventoryFromBigCommerceViaGraphQL = CacheInventory(async ({
  productId,
  variantId = 0,
}: {
  productId: number,
  variantId?: number,
}) => {
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const response = await Axios.post<{ data: { site: { products: {
      edges: Array<{
        node: {
          entityId: number,
          name: string,
          inventory: {
            isInStock: boolean,
            aggregated: {
              availableToSell: number,
            },
          },
          variants: {
            edges: Array<{
              node: {
                entityId: number,
                inventory: {
                  aggregated: {
                    availableToSell: number,
                  },
                  isInStock: boolean,
                },
              },
            }>,
          },
        },
      }>,
    } } } }>(
      `${SHOP_ORIGIN}/graphql`,
      {
        query: `  query getProductVariantsInventor(
          $productId: Int! $variantId: Int!
        ) {
          site {
            products (first: 1, entityIds:[$productId]) {
              edges {
                node {
                  entityId
                  name
                  inventory{
                    isInStock
                    aggregated {
                      availableToSell
                    }
                  }
                  variants(first: 1, entityIds: [$variantId]){
                  edges{            
                   node{
                     entityId
                     inventory{
                       isInStock
                       aggregated {
                         availableToSell
                       }
                     }
                   }
                 } 
                  }
                }
              }
            }
          }
        }
        `,
        variables: { productId, variantId },
      },
      GraphQLHeaders(),
    )
    const product = response?.data?.data?.site?.products?.edges?.[0]?.node
    const inventory = (
      variantId === 0
        ? product.inventory?.aggregated?.availableToSell
        : product?.variants?.edges?.[0]?.node?.inventory?.aggregated?.availableToSell
      || 0
    )
    return { inventory, success: true }
  } catch (err) {
    error('Failed to getProductInventory', err)
    return { inventory: 0, success: false }
  }
})

export type GraphQlCustomer = {
  entityId: number,
  company: string,
  customerGroupId: number,
  email: string
  firstName: string
  lastName: string,
  notes: string,
  phone: string
  taxExemptCategory: string
  addressCount: number
  attributeCount: number
}
type AxiosGraphQLGetCustomer = {
  data: {
    customer: null | GraphQlCustomer
  }
}
type GraphQLGetCustomerRequest = (p?: { pathPrefix?: string, headers?: HeadersType }) =>
Promise<{ success: true, customer: GraphQlCustomer } | { success: false, customer: null }>

export const getCustomer: GraphQLGetCustomerRequest = async ({ pathPrefix = '', headers = {} } = {}) => {
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const { data } = await Axios.post<AxiosGraphQLGetCustomer>(
      `${pathPrefix}/graphql`,
      {
        query: `query customer {
          customer {
            entityId
            company
            customerGroupId
            email
            firstName
            lastName
            notes
            phone
            taxExemptCategory
            addressCount
            attributeCount
          }
        }`,
      },
      GraphQLHeaders(headers),
    )
    const customer = data?.data?.customer
    if (customer) {
      return { success: true, customer }
    }
  } catch {
    // fail silently
  }
  return { success: false, customer: null }
}
