import App, { AppContext, AppProps } from 'next/app'
import type { NextRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import * as SitewideComponents from '@/components/Sitewide'
import { logVitals } from '@/components/Sitewide/webVitals'

// These were ambiguous-build-order global imports I am consolidating here
import '@csc/dls/dist/index.css'
// For the PLP page (including search, yes i know it's misnamed)
import '@/styles/category.css'
// base normalization of HTML and BODY nodes
import '@/components/Checkout/normalize.css' // RISKY ONE...
import '@/styles/extend.scss'
// These are global, though COULD be modules, and classnames non-intersecting.
import '@/components/Generic/Carousel/carousel.css'
import '@/components/QuickLook/styles.css'
import '@/components/Search/SearchNotFound.scss' // less risky now, not a module but also not global namespace

// These are global SCSS, just like the CSS ones above...
import '@/styles/cart.scss'
import '@/styles/header.scss'

// These are local files, with global imports - I am taking
//   a shot at resolving them to parent...
import '@/components/Pdp/AddProductToCartForm/style.scss'
import '@/components/Pdp/style.scss'
import '@/components/Pdp/ProductImagesCarousel/style.scss'
import '@/components/Pdp/AccordionProductDetails/style.scss'
import '@/components/ProductTile/style.scss'
import '@/components/Content/CarouselWithCopy/styles.scss'
import '@/components/Content/CollectionCarousel/styles.scss'

import '@/components/Sitewide/Navbar/styles/fullWidthMenu.scss'
import '@/components/Sitewide/Navbar/styles/seconNavMenu.scss'
import '@/components/Sitewide/Navbar/styles/hamburgerMenu.scss'
import '@/styles/tailwind.scss'

import { log } from '@/services/Log'
import dynamic from 'next/dynamic'
import {
  BOUNCEX_ID,
  GA_TRACKING_ID,
  GTM_TRACKING_ID,
} from '@/services/Configuration'

import { trackViewPageTikTok } from '@/services/TikTokPixel'
import useRouteChangeComplete from '@/hooks/useRouteChangeComplete'
import { NextPageContext } from 'next'
import { StripeRadar } from '@/components/ScriptIntegrations/StripeRadar'
import useTrackGTMUserInfo from '@/hooks/useTrackGTMUserInfo'
import { timePromise } from '@/helpers/logWithTiming'
import Head from 'next/head'
import { checkForBigCommerceRedirect } from '@/helpers/navigationHelpers/checkForBigCommerceRedirect'
import { appSSRFeatureTogglesSpy } from '@/helpers/isFeatureEnabled'

type BannerGetInitialProps = Awaited<typeof import('@/components/Sitewide/Banner/index')>['default']['getInitialProps']
type NavbarGetInitialProps = Awaited<typeof import('@/components/Sitewide/Navbar/index')>['default']['getInitialProps']
type SitewieInitialComponents = Record<string, BannerGetInitialProps | NavbarGetInitialProps>

const GoogleTagManager = dynamic(import('@/components/ScriptIntegrations/GoogleTagManager'))
const ExtendWarranty = dynamic(import('@/components/ScriptIntegrations/ExtendWarranty'))
const BounceX = dynamic(import('@/components/ScriptIntegrations/BounceX'))
const CitizensPay = dynamic(import('@/components/ScriptIntegrations/CitizensPay'))
const GoogleMapsScript = dynamic(import('@/components/ScriptIntegrations/GoogleMapsScript'))
const GoogleOptimizeScript = dynamic(import('@/components/ScriptIntegrations/GoogleOptimizeScript'))
const GoogleAnalytics = dynamic(import('@/components/ScriptIntegrations/GoogleAnalytics'))
const FacebookPixel = dynamic(import('@/components/ScriptIntegrations/FacebookPixel'))

type CustomAppProps = {
  // TODO: Add more types in the future
  query: NextRouter['query']
  path: string,
  returnAPIOnly: boolean,
}

const MyApp = ({
  Component,
  pageProps,
  sitewideComponents: propsSitewideComponents,
}: AppProps<CustomAppProps> & {
  sitewideComponents: SitewieInitialComponents
}) => {
  useTrackGTMUserInfo()
  // If user navigates without SSR, then getInitialProps in MyApp isn't called and header disappears
  // So we save state of sitewideComponents and send it instead of empty object
  const [sitewideComponents, setSitewideComponents] = useState({})

  const areSitewideComponentsExistingInProps = useMemo(() => (
    propsSitewideComponents
      && typeof propsSitewideComponents === 'object'
      && propsSitewideComponents.constructor === Object
      && !!Object.keys(propsSitewideComponents).length
  ), [propsSitewideComponents])

  useEffect(() => {
    if (areSitewideComponentsExistingInProps) {
      setSitewideComponents(propsSitewideComponents)
    }
  }, [propsSitewideComponents, areSitewideComponentsExistingInProps])

  useEffect(() => {
    logVitals()
  }, [])

  useEffect(() => {
    trackViewPageTikTok()
  }, [])

  useRouteChangeComplete(trackViewPageTikTok)

  if (pageProps.returnAPIOnly) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...pageProps} />
  }

  return (
    <>
      <Head>
        {/* ZGBE-1084 Marketing verification assessment */}
        <meta name="google-site-verification" content="BXw7mJA8317JQKwU7pcX7-o1xlXkRnsB9TkldpJv3Dw" />
      </Head>
      <link id="jquery-link" rel="preload" href="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js" />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js" />
      <StripeRadar />
      <GoogleTagManager googleTrackingId={GTM_TRACKING_ID} />
      <GoogleAnalytics googleAnalyticsId={GA_TRACKING_ID} />
      <FacebookPixel />
      <ExtendWarranty />
      <BounceX bouncexId={BOUNCEX_ID} />
      <CitizensPay />
      <GoogleMapsScript />
      <GoogleOptimizeScript />
      <Component
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...pageProps}
        sitewide={areSitewideComponentsExistingInProps
          ? propsSitewideComponents
          : sitewideComponents}
      />
    </>
  )
}

type GetSitewideComponents = () => (
  Promise<Record<string, (ctx: NextPageContext) => Promise<unknown>>>
)

const getSitewideComponents: GetSitewideComponents = async () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      Object.assign({}, ...(await Promise.all(
        Object.values(SitewideComponents)
          .filter((component) => typeof component.getInitialProps === 'function')
          .map(async (component) => component?.getInitialProps?.()),
      )))
    )
  } catch {
    return {}
  }
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  let pageProps = {}
  let sitewideComponents = {}
  const path = String(appContext.ctx.asPath || '')
  try {
    if (typeof window === 'undefined') {
      appSSRFeatureTogglesSpy(appContext)
    }
    pageProps = await timePromise({ event: 'getAppInitialProps' })(App.getInitialProps(appContext))
    if (path.startsWith('/ssr-api')) {
      return {
        ...pageProps,
        returnAPIOnly: true,
      }
    }
    const bigCommerceRedirectVerification = timePromise({ event: 'checkForBigCommerceRedirect' })(checkForBigCommerceRedirect(appContext.ctx))
    sitewideComponents = await timePromise({ event: 'getSitewideComponents' })(getSitewideComponents())
    await bigCommerceRedirectVerification
  } catch (e) {
    log(e)
  }

  return {
    ...pageProps,
    sitewideComponents,
    returnAPIOnly: false,
  }
}

export default MyApp
