const setFeatureToggles = require('../services/featureToggles')

const hostedZoneId = 'Z2114D6TD4C7GZ'
const domainName = 'staging-shop.zgallerie.com'
const environmentName = 'staging'
const PublicCdnDnsName = 'stagingzgalleriecdn.com'
const CDN_PREFIX = 'https://staging-shop.zgallerie.com'

module.exports = {
  AWS_REGION: 'us-east-1',
  CDN_PREFIX,
  AWS_PROFILE: 'zgallerie',
  BOPUS_FIELD: 'field_37',
  CATEGORY_PAGE_VERSION_FIELD: 'field_55',
  BOPUS_STORE_FIELD: 'field_39',
  APPLE_PAY_FIELD: 'field_61',
  ZGOLD_REDEEMED_FIELD: 'field_49',
  // In reason of changing any CitizensPay configuration, be sure to make changes
  // also on Shogun for t-citizens-pay landing page
  CONFIG_NAME: 'zgallerie-staging',
  CONFIG_FILE: 'staging.json',
  CONTENT_PREVIEW: true,
  CITIZENS_SCRIPT: 'https://toolbox-uat.jifiti.com/Widgets/1.0.4/jifiti-widget.min.js',
  CITIZENS_WIDGET_KEY: 'a68c78a25e3449ab8494bcf7c94ad27f',
  DOMAIN_NAME: domainName,
  ENABLE_CITIZENS: 'true',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDkkr7TBQj4KS2gxkt1IXV4X1gI_JDehvU',
  CHECKOUT_DOMAIN: '/checkout-frame',
  CHECKOUT_IDENTIFIER: 'zgallerie-staging',
  ENVIRONMENT_NAME: environmentName,
  CONTENT_API: 'https://z0eymfbmj1.execute-api.us-east-1.amazonaws.com/Prod/v1',
  INVENTORY_API: 'https://staging-pat.zgallerie.com',
  PARKY_API: '/parky',
  SEARCH_API_V2: 'https://fsq82ojmi7.execute-api.us-east-2.amazonaws.com/Stage',
  SEARCH_ORIGIN: 'https://staging-parky-distribution.zgallerie.com',
  SHOP_ORIGIN: 'https://staging-shop.zgallerie.com',
  PRODUCT_INDEX: 'zg_products',
  CATEGORY_INDEX: 'zg_categories',
  PAGE_INDEX: 'zg_pages',
  TIKTOK_PIXEL_ID: 'CHHR0P3C77UCGKF7E18G',
  FACEBOOK_PIXEL_ID: '1095267580904127',
  SHOP_HASH: 'u6ub4rctfv',
  LEASECO_ID: 'a5980cae-d718-4b21-ba46-86d9b0d5acd5',
  GTM_TRACKING_ID: 'GTM-TCLCVLZ',
  GA_TRACKING_ID: 'G-S7H96WQC6X',
  DeployParameterOverrides: JSON.stringify({
    DomainName: domainName,
    EnvironmentName: environmentName,
    HostedZoneId: hostedZoneId,
    StaticsBucketDnsName: 'statics-zgallerie-ssr-next-us-east-1-746571731253-staging.s3.amazonaws.com',
    PublicCdnDnsName,
    PublicCdnHostedZoneId: 'Z08691751QAFGMUQ2ADD6',
  }),
  READONLY_BC_TOKEN: 'ft9hj1gfwjr6rf4rwz05w6zuztwydz4',
  RESERVATION_NUMBER_FIELD: 'field_43',
  RECREATE_URL: null,
  DTP_NUMBER_FIELD: 'field_45',
  CHECKOUT_VERSION_FIELD: 'field_47',
  STORE_CREDITS_APPLIED_FIELD: 'field_51',
  IS_CITIZENS_PAY_ORDER_FIELD: 'field_53',
  FEATURE_TOGGLES: setFeatureToggles(environmentName),
  VITALS_LOGGING_API: 'https://xsyn3lh88d.execute-api.us-east-1.amazonaws.com/Prod',
  SPIFFY_URL: '127.0.0.1:3000',
  GA_DIMENSION: 'dimension1',
  CHECKOUT_EXPERIMENT_ID: 'bT5rOGT-SEiShcslZkXN-w',
  PLACES_KEY: 'AIzaSyBWw0YpBr5L2IZwkjPErjDnZrqIi2Iw-8c',
  LISTRAK_ID: 'EmCC5iEfX18F',
  AW_TRACKING_ID: 'AW-1044894252',
  BOUNCEX_ID: '4315',
  CONTENT_SQUARE_ID: '10f0a6dd10cc2',
  CONTENTFUL_ACCESS_TOKEN: 'I5HYKTK43eGEHm1qpyzESUuZLgIw4R151hDzAcdvQT8',
  CONTENTFUL_SPACE_ID: 'p62egiw5uahp',
  CONTENTFUL_ENVIRONMENT: 'master',
  EXTEND_ID: '9bb7b50b-96fc-4728-a6e6-2fd2a893ec39',
  EXTEND_ENVIRONMENT: 'demo',
  CITIZENS_PAY_EXPERIMENT_ID: 'n9wjfKA_REa8-8SHMb6cjQ',
  SENTRY_DSN: 'https://e17ae7752d684301a9f05c3640902566@o32694.ingest.sentry.io/4505110708420608',
  SHOP_TOKEN_SCRIPT: 'https://web-js-sandbox.bigshoptoken.com/main.js',
  GraphQLStoreFrontJWT: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOjEsImNvcnMiOlsiaHR0cHM6Ly9zdGFnaW5nLXNob3AuemdhbGxlcmllLmNvbSJdLCJlYXQiOjE3NDYwNjY5MTksImlhdCI6MTY0MTU4MDEzOCwiaXNzIjoiQkMiLCJzaWQiOjEwMDA2MDI3MjEsInN1YiI6ImdzZHFobmdhZnVyMTR0dGw4ZTRoems4dnJycHZlZ2giLCJzdWJfdHlwZSI6MiwidG9rZW5fdHlwZSI6MX0.jP5HHXzoaYq2MYlNafrWM-rgJKysW3d9w1fht0sqJgSvjURGO6NjJ7lD-tP_Y5qGHmULhHrj7TVYGSnObCrFKQ', // expires at 1746066919 Thu May 01 2025 02:35:19 GMT+0000
  VWO_SETTINGS: {
    ACCOUNT_ID: 681563,
    TOLERANCE: 2000,
    LIBRARY_TOLERANCE: 2500
  },
  OPTIMIZE_CONTAINER_ID: 'OPT-PWB434S',
  PAYPAL_ID: 'AVRxdEMfkwwIZtqIq2HDuzgD6yE6EceSiKzL2etjo9yZMx1M9TohjQMPSzOvsO6AmMGRK-0O2anYQ7Ts',
  CSC_SHOPFRONT_API: 'api.cscshopfront-dev.com',
  CSC_PAYMENTS_HOST: 'api.cscpaymentsserviesdev.com',
  CSC_PAYMENTS_PK: 'pk_test_51MkVrfLTg5aij8f1qJR2r4SYwhcpqqRlA2DlbfrUu6yr0IV5kwvmlyhRKr4pykJzEbfKaIr9hO9KMtkVcTdp4Sce00XCJ0lVrd',
  CSC_PAYMENTS_GATEWAY: 'zg-staging-stripe',
  CSC_PAYPAL_GATEWAY: 'zg-staging-paypal',
  CSC_PAYMENTS_SHOP: 'zg-staging',
  CSC_IAM_AUD: '780roy7uw34yaei7vpcwq4cvt7sim7u',
  CSC_IAM_REGISTERED_IDP: 'zg-staging-registered',
  CSC_IAM_GUEST_IDP: 'zg-staging-guests',
  CSC_IAM_REGISTERED_ROLE: 'SignedInStagingzgShopFrontCustomer',
  CSC_IAM_GUEST_ROLE: 'GuestStagingzgShopFrontCustomer',
  GUEST_TOKEN_NAME: 'guestToken',
  CSC_IAM_ACCOUNT: '123456654321',
  SHIPPEDSHIELD_EXPERIMENT_ID: 'o8K7Gto5RK2okOnF3AIPPA',
  USE_LOCAL_CSC_CREDENTIALS: false,
  DMPFiltersDataSetName: 'test-anunes-dmptable011',
  DMPMainDataSetName: 'zg-index-001',
  OPEN_SEARCH_ORIGIN: 'https://vpc-zgallerie-search-staging-xm57nql5b6l23rdymd4dawcrpq.us-east-2.es.amazonaws.com',
}
