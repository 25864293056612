import { ClientSideCacheWithGeneric } from '@/services/ClientSideCache'
import { getComponent } from '@/services/Content/getComponent'

type TypeAndIdInput = {
  type: string
  id: number | string
}

type GetComponentRequirement = {
  message: string
}

interface Getter {
  <T extends GetComponentRequirement>(input: TypeAndIdInput): Promise<T>
}

const getExecutor: Getter = async <T extends GetComponentRequirement>(
  { type, id }: TypeAndIdInput,
) => (
  getComponent<T>({ type, id: String(id) })
)

const hashGetterInoput = ({ type, id }: TypeAndIdInput) => `${type}-${id}`

const get = (
  ClientSideCacheWithGeneric(hashGetterInoput)(getExecutor)
) as <T>(input: TypeAndIdInput) => Promise<T>

export const Sitewide = {
  get,
}
export default Sitewide
